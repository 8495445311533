
import React, {
  lazy, Suspense, useCallback, useEffect, useMemo, useState,
} from 'react';
import _, { isEmpty, isNull } from 'lodash';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect, useHistory,
} from 'react-router-dom';
import {
  CssBaseline, ThemeProvider, createTheme,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';

import {
  loginAction, logoutAction, signupAction,
  resumeLastSessionAction, acceptInvitationWithRegisterAction, wxloginAction,
} from '@ivymobi/orbit-core/business/user';
import configureStore from '@ivymobi/orbit-core/business/configureStore';
import { acceptInvitationWithLoginedSessionAction, getWechatAccessTokenByCode } from '@ivymobi/orbit-core/business/tenant';

import './services/i18n';
import * as api from './api';

import themeConfig from './services/theme';
import Loader from './components/loaders';
import Notification from './layout/notification';
import ErrorBoundary from './components/errorBoundles';

import User from './users';
import Invitations from './users/invitations';
import WechatCode from './users/wechatCode';
import { InnerUserContext, ThemeContext, UserContext, IframeLoginContext } from './orbit-context';
import { queryParse } from '@ivymobi/orbit-core';

// 不需要写到根组件
// import { AuthProvider } from './users/oidc/AuthProvider';

// import ActiveUserContainer from './user';
// import Share from './shares';

const ActiveContainer = lazy(
  () => import('./mycontain' /* webpackChunkName: "user" */)
);
// import ActiveContainer from './mycontain';

// const Share = lazy(
//   () => import('./shares' /* webpackChunkName: "share" */)
// );

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%',
      // minWidth: 'fit-content',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
  })
);

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const _innerUser = queryParse(window.location.search);
  const [innerUser, setInnerUser] = useState(_innerUser??{});
  const [user, setUser] = useState(null);

  useEffect(() => {
    // console.log('App启动后，自动上次的登录用户');

    dispatch(resumeLastSessionAction()).then(
      (userData) => {
        userData.logout = logout;
        setUser(userData);
      },
      () => setUser({}));
  }, []);
  const doLogin = (userData) => {
    userData.logout = logout;
    setUser(userData);
  };

  const login = useCallback((loginData) => {
    dispatch(loginAction(loginData))
    .then(
      (userData) => {
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      }
    );
  }, [dispatch]);

  const signup = useCallback((signupData) => {
    dispatch(signupAction(signupData))
    .then(
      (userData) => {
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      });
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutAction())
    .then(() => {
      setUser({});
    });
  }, [dispatch, setUser]);

  const [iframeLogin, setIframeLogin] = useState(false);
  useEffect(() => {
    // ------------------------------先注释掉，换一个方法测试-------------------------------
    // const handleMessage = (event) => {
    //   const data = event.data;
    //   // // 在数据中查找你传递的参数
    //   // const loginStatus = _.get(data, ['loginStatus'], false);
    //   console.log('监听信息', {data}); 
    //   if (_.has(data, 'loginStatus')) {
    //     console.log('----------------登录状态----------------------'); 
    //     setIframeLogin(true);
    //   }
    // };
    // window.addEventListener('message', handleMessage);
    // return () => {
    //   window.removeEventListener('message', handleMessage);
    // };
    // --------------------------end-------------------------------------------------

    // 另一个方法，检测当前智能样本是不是通过iframe等方式嵌套，如果嵌套，则默认为登录状态
    // console.log('window.topwindow.topwindow.topwindow.topwindow.topwindow.topwindow.top=============', window.self === window.top);
    const isIframe = window.self !== window.top;
    setIframeLogin(isIframe);

  }, []);


  return (
    <div className={classes.root}>
      <UserContext.Provider value={user}>
        <InnerUserContext.Provider value={innerUser}>
          <IframeLoginContext.Provider value={iframeLogin}>
            <Switch>
              <ActiveContainer doLogin={doLogin} logout={logout}/>
            </Switch>
          </IframeLoginContext.Provider>
        </InnerUserContext.Provider>
      </UserContext.Provider>
      
    </div>
  );
};

export default function OrbitApp() {
  const store = configureStore(api, {});
  const [mainColor, setMainColor] = useState('#005ae7');
  let [theme, setTheme] = useState(createTheme(themeConfig));
  const handleChangeTheme = (color) => {
    if (color === mainColor) return;
    theme.palette.primary.main = color;
  };
  return (
    // <AuthProvider>
    <BrowserRouter>
      <ThemeContext.Provider value={handleChangeTheme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Suspense fallback={<Loader />} >
              <Main />
              <Notification /> 
            </Suspense> 
            {/* <Loader /> */}
            {/* 提示信息组件报错？？ */}
            
          </Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </BrowserRouter>
    // </AuthProvider>
  );
}
