import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar, {
  SnackbarCloseReason, SnackbarProps
} from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  hideNotificationAction, useNotification
} from '@ivymobi/orbit-core/business/notification';
import { useTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Notification: React.FunctionComponent<
  Omit<SnackbarProps, 'open'>
> = props => {
  const {
    autoHideDuration,
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const notification = useNotification();
  const dispatch = useDispatch();
  const type = notification?.type ?? 'info';

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback((
    event: React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if ((notification?.type !== 'info') && (reason === 'clickaway')) {
      return;
    }
    setOpen(false);
  }, [setOpen, notification?.type]);

  const handleExited = useCallback(() => {
    dispatch(hideNotificationAction());
  }, [dispatch]);

  const hideDuration = notification?.autoHideDuration
    ?? autoHideDuration;

  const message = notification?.message
    ? t(`notification.${notification?.message}`, notification?.message)
    : '';
  if (!open) {
    return null;
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={hideDuration}
      TransitionProps={{
        onExited: () => {
          handleExited();
        }
      }}
      onClose={handleRequestClose}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};


Notification.defaultProps = {
  autoHideDuration: 4000,
};

export default Notification;
