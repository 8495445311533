
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles<Theme>((them: Theme) => createStyles({
  'container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fafafa',
  },
  'loader': {
    borderRadius: '50%',
    color: grey[600],
    fontSize: 11,
    textIndent: '-999999em',
    margin: '55px auto',
    position: 'relative',
    width: '10em',
    height: '10em',
    boxShadow: 'inset 0 0 0 1em',
    transform: 'translateZ(0)',
    '&:before': {
      position: 'absolute',
      content: "''",
      width: '5.2em',
      height: '10.2em',
      background: '#fafafa',
      borderRadius: '10.2em 0 0 10.2em',
      top: '-0.1em',
      left: '-0.1em',
      transformOrigin: '5.2em 5.1em',
      animation: '$load2 2s infinite ease 1.5s'
    },
    '&:after': {
      position: 'absolute',
      content: "''",
      width: '5.2em',
      height: '10.2em',
      background: '#fafafa',
      borderRadius: '0 10.2em 10.2em 0',
      top: '-0.1em',
      left: '5.1em',
      transformOrigin: '0 5.1em',
      animation: '$load2 2s infinite ease'
    }
  },
  '@keyframes load2': {
    '0%': {
      'transform': 'rotate(0deg)',
    },
    '100%': {
      'transform': 'rotate(360deg)'
    }
  }
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.loader}>Loading...</div>
    </div>
  );
};

export default Loader;
