import _ from 'lodash';
import { findKey, isUndefined } from 'lodash';
import copy from 'copy-to-clipboard';
import { notifyReducer } from '../business/notification';
import { useDispatch } from 'react-redux';
import { sendLoginMobileCodeAction, sendNotifyAction } from '../business/user';
/**
 * Enumeration of icon file names, and what extensions they map to.
 * Please keep items alphabetical. Items without extensions may require specific logic in the code to map.
 * Track icon accuracy status here:
 *  https://msft-my.spoppe.com/personal/caperez_microsoft_com/Documents/Missing%20List.xlsx?web=1
 */
const FileTypeIconMap: { [key: string]: { extensions?: string[] } } = {
  accdb: { extensions: ['accdb', 'mdb'] },
  archive: {
    extensions: [
      '7z', 'ace', 'arc', 'arj', 'dmg',
      'gz', 'iso', 'lzh', 'pkg', 'rar',
      'sit', 'tgz', 'tar', 'z'
    ],
  },
  audio: {
    extensions: [
      'aif',
      'aiff',
      'aac',
      'amr',
      'au',
      'awb',
      'dct',
      'dss',
      'dvf',
      'flac',
      'gsm',
      'm4a',
      'm4p',
      'mid',
      'mmf',
      'mp3',
      'ogg',
      'oga',
      'ra',
      'rm',
      'wav',
      'wma',
      'wv',
    ],
  },
  code: {
    extensions: [
      'abap',
      'ada',
      'adp',
      'ahk',
      'as',
      'as3',
      'asc',
      'ascx',
      'asm',
      'asp',
      'awk',
      'bash',
      'bash_login',
      'bash_logout',
      'bash_profile',
      'bashrc',
      'bat',
      'bib',
      'bsh',
      'build',
      'builder',
      'c',
      'c++',
      'capfile',
      'cc',
      'cfc',
      'cfm',
      'cfml',
      'cl',
      'clj',
      'cls',
      'cmake',
      'cmd',
      'coffee',
      'config',
      'cpp',
      'cpt',
      'cpy',
      'cs',
      'cshtml',
      'cson',
      'csproj',
      'css',
      'ctp',
      'cxx',
      'd',
      'ddl',
      'di',
      'disco',
      'dml',
      'dtd',
      'dtml',
      'el',
      'emakefile',
      'erb',
      'erl',
      'f',
      'f90',
      'f95',
      'fs',
      'fsi',
      'fsscript',
      'fsx',
      'gemfile',
      'gemspec',
      'gitconfig',
      'go',
      'groovy',
      'gvy',
      'Hcp',
      'h',
      'h++',
      'haml',
      'handlebars',
      'hbs',
      'hh',
      'hpp',
      'hrl',
      'hs',
      'htc',
      'hxx',
      'idl',
      'iim',
      'inc',
      'inf',
      'ini',
      'inl',
      'ipp',
      'irbrc',
      'jade',
      'jav',
      'java',
      'js',
      'json',
      'jsp',
      'jsx',
      'l',
      'less',
      'lhs',
      'lisp',
      'log',
      'lst',
      'ltx',
      'lua',
      'm',
      'make',
      'manifest',
      'master',
      'md',
      'markdn',
      'markdown',
      'mdown',
      'mkdn',
      'ml',
      'mli',
      'mll',
      'mly',
      'mm',
      'mud',
      'nfo',
      'opml',
      'osascript',
      'p',
      'pas',
      'patch',
      'php',
      'php2',
      'php3',
      'php4',
      'php5',
      'phtml',
      'pl',
      'pm',
      'pod',
      'pp',
      'profile',
      'ps1',
      'pss',
      'pt',
      'py',
      'pyw',
      'r',
      'rake',
      'rb',
      'rbx',
      'rc',
      'rdf',
      're',
      'reg',
      'rest',
      'resw',
      'resx',
      'rhtml',
      'rjs',
      'rprofile',
      'rpy',
      'rss',
      'rst',
      'ruby',
      'rxml',
      's',
      'sass',
      'scala',
      'scm',
      'sconscript',
      'sconstruct',
      'script',
      'scss',
      'sgml',
      'sh',
      'sh',
      'shtml',
      'sml',
      'svn-base',
      'swift',
      'sql',
      'sty',
      'tcl',
      'tex',
      'textile',
      'tld',
      'tli',
      'tmpl',
      'tpl',
      'vb',
      'vi',
      'vim',
      'vmg',
      'webpart',
      'wsp',
      'wsdl',
      'xhtml',
      'xoml',
      'xsd',
      'xslt',
      'yaml',
      'yaws',
      'yml',
      'zsh',
    ],
  },
  css: {}, // we dont have the icon yet, but i believe we want it, snapping to 'code' for now
  csv: { extensions: ['csv'] },
  docset: {},
  docx: { extensions: ['doc', 'docm', 'docx'] },
  dotx: { extensions: ['dot', 'dotm', 'dotx'] },
  email: { extensions: ['eml', 'msg', 'ost', 'pst'] },
  exe: { extensions: ['application', 'appref-ms', 'apk', 'app', 'appx', 'exe', 'ipa', 'msi', 'xap'] },
  folder: {},
  font: { extensions: ['ttf', 'otf', 'woff'] },
  genericfile: {},
  html: { extensions: ['htm', 'html', 'mht'] },
  link: { extensions: ['lnk', 'link', 'url', 'website', 'webloc'] },
  listitem: { extensions: ['listitem'] },
  model: {
    extensions: [
      '3ds',
      '3mf',
      'blend',
      'cool',
      'dae',
      'df',
      'dwfx',
      'dwg',
      'dxf',
      'fbx',
      'glb',
      'gltf',
      'holo',
      'layout',
      'max',
      'mtl',
      'obj',
      'off',
      'ply',
      'skp',
      'stp',
      'stl',
      't',
      'thl',
      'x',
    ],
  },
  mpp: { extensions: ['mpp'] },
  mpt: { extensions: ['mpt'] },
  one: { extensions: ['one', 'onepkg'] }, // this is a format for exported single-file notebook pages
  onetoc: { extensions: ['ms-one-stub', 'onetoc', 'onetoc2'] }, // this icon represents a complete, logical notebook.
  pdf: { extensions: ['pdf'] },
  photo: {
    extensions: [
      'arw',
      'bmp',
      'cr2',
      'crw',
      'dcr',
      'dds',
      'dib',
      'dng',
      'erf',
      'gif',
      'ico',
      'jfi',
      'jfif',
      'jif',
      'jpe',
      'jpeg',
      'jpg',
      'kdc',
      'mrw',
      'nef',
      'orf',
      'pct',
      'pict',
      'png',
      'pns',
      'psd',
      'raw',
      'tga',
      'tif',
      'tiff',
      'wdp',
    ],
  },
  potx: { extensions: ['pot', 'potm', 'potx'] },
  ppsx: { extensions: ['pps', 'ppsm', 'ppsx'] },
  pptx: { extensions: ['ppt', 'pptm', 'pptx'] },
  presentation: { extensions: ['odp', 'gslides', 'key'] },
  pub: { extensions: ['pub'] },
  rtf: { extensions: ['epub', 'gdoc', 'odt', 'rtf', 'wri', 'pages'] },
  sharedfolder: {},
  spo: { extensions: ['aspx'] },
  spreadsheet: { extensions: ['ods', 'gsheet', 'numbers'] },
  sysfile: {
    extensions: [
      'bak',
      'bin',
      'cab',
      'cache',
      'cat',
      'cer',
      'class',
      'dat',
      'db',
      'dbg',
      'dl_',
      'dll',
      'ithmb',
      'jar',
      'kb',
      'kdc',
      'ldt',
      'lrprev',
      'ppa',
      'ppam',
      'pdb',
      'rom',
      'thm',
      'thmx',
      'vsl',
      'xla',
      'xlam',
      'xll',
    ],
  },
  txt: { extensions: ['dif', 'diff', 'readme', 'out', 'plist', 'properties', 'text', 'txt'] },
  vector: {
    extensions: [
      'ai',
      'dgn',
      'gdraw',
      'pd',
      'emf',
      'eps',
      'indd',
      'indt',
      'ps',
      'svg',
      'svgz',
      'wmf',
      'oxps',
      'xps',
      'xd',
      'sketch',
    ],
  },
  video: {
    extensions: [
      '3gp',
      'asf',
      'avi',
      'dvr-ms',
      'flv',
      'm1v',
      'm4v',
      'mkv',
      'mod',
      'mov',
      'mm4p',
      'mp2',
      'mp2v',
      'mp4',
      ' mpa',
      'mpe',
      'mpeg',
      'mpg',
      'mpv',
      'mpv2',
      'mts',
      'ogg',
      'qt',
      'swf',
      'ts',
      'vob',
      'webm',
      'wlmp',
      'wm',
      'wmv',
      'wmx',
    ],
  },
  vsdx: { extensions: ['vsd', 'vsdm', 'vsdx', 'vdw'] },
  vssx: { extensions: ['vss', 'vssm', 'vssx'] },
  vstx: { extensions: ['vst', 'vstm', 'vstx'] },
  xlsx: { extensions: ['xls', 'xlsb', 'xlsm', 'xlsx'] },
  xltx: { extensions: ['xlt', 'xltm', 'xltx'] },
  xml: { extensions: ['xaml', 'xml', 'xsl'] },
  xsn: { extensions: ['xsn'] },
  zip: { extensions: ['zip'] },
};

export function getExtname(filename) {
  return filename.split('.').reverse()[0] || '';
}

export function getFileType(filename) {
  const extname = getExtname(filename).toLowerCase();
  return findKey(FileTypeIconMap, ({extensions = []}) => {
    return extensions.includes(extname);
  }) || 'genericfile';
}

export function getIsOfficelType(filetype) {
  const officelType = ['docx', 'pptx', 'xlsx'];
  return officelType.includes(filetype);
}

export function getIsOtherType(filetype) {
  const acceptType = ['video', 'photo', 'txt', 'pdf', 'pptx', 'xlsx', 'docx'];
  const isOther = acceptType.indexOf(filetype) < 0;
  return isOther;
}
export function getFileSuffix(filename,originName) {
  const extname = getExtname(originName).toLowerCase();
  const ext1name = getExtname(filename).toLowerCase();
  if (extname===ext1name) {
    return ''
  } else {
    return '.'+extname;
  }
}
export function isJSON(str) {
	if (typeof str == 'string') {
	    try {
	        var obj=JSON.parse(str);
	        if(typeof obj == 'object' && obj ){
	            return true;
	        }else{
	            return false;
	        }

	    } catch(e) {
	        return false;
	    }
	}
}
export function DownloadFile(url,name,window,dispatch=null){

  var ua = navigator.userAgent.toLowerCase();
  var isWeixin1 = ua.indexOf('micromessenger') != -1;
  if(isWeixin1){
   if(dispatch)
      dispatch(sendNotifyAction({ message:'下载链接已复制 请打开浏览器下载' }))

    copy(url+'?attname='+name);
    return false
  }
  const anchor = window.document.createElement('a');
  const body = window.document.querySelector('body');
  anchor.href = url+'?attname='+name;
  anchor.download = name;
  anchor.style.display = 'none';
  body.appendChild(anchor);
  anchor.click();
  body.removeChild(anchor);
  window.URL.revokeObjectURL(anchor.href);
  return true;
}
const FileTypeMap: { [key: string]: { extensions?: string[] } } = {
  audio: {
    extensions: [
      'aif',
      'aiff',
      'aac',
      'amr',
      'au',
      'awb',
      'dct',
      'dss',
      'dvf',
      'flac',
      'gsm',
      'm4a',
      'm4p',
      'mid',
      'mmf',
      'mp3',
      'ogg',
      'oga',
      'ra',
      'rm',
      'wav',
      'wma',
      'wv',
    ],
  },

  docx: { extensions: ['doc', 'docm', 'docx'] },
  folder: {},
  genericfile: {},
  pdf: { extensions: ['pdf'] },
  photo: {
    extensions: [
      'arw',
      'bmp',
      'cr2',
      'crw',
      'dcr',
      'dds',
      'dib',
      'dng',
      'erf',
      'gif',
      'ico',
      'jfi',
      'jfif',
      'jif',
      'jpe',
      'jpeg',
      'jpg',
      'kdc',
      'mrw',
      'nef',
      'orf',
      'pct',
      'pict',
      'png',
      'pns',
      'psd',
      'raw',
      'tga',
      'tif',
      'tiff',
      'wdp',
    ],
  },
  pptx: { extensions: ['ppt', 'pptm', 'pptx'] },
  txt: { extensions: ['text', 'txt'] },
  video: {
    extensions: [
      '3gp',
      'asf',
      'avi',
      'dvr-ms',
      'flv',
      'm1v',
      'm4v',
      'mkv',
      'mod',
      'mov',
      'mm4p',
      'mp2',
      'mp2v',
      'mp4',
      'mpa',
      'mpe',
      'mpeg',
      'mpg',
      'mpv',
      'mpv2',
      'mts',
      'ogg',
      'qt',
      'swf',
      'ts',
      'vob',
      'webm',
      'wlmp',
      'wm',
      'wmv',
      'wmx',
    ],
  },
  xlsx: { extensions: ['xls', 'xlsb', 'xlsm', 'xlsx'] },
};

export function canDistinguish(filetype, filename) {

  if (filetype==='weblink') {
    return false;
  } else if (filetype==='folder') {
    return true;
  } else {
    if (isUndefined(filename))
      return false;
    const extname = getExtname(filename).toLowerCase();
    return findKey(FileTypeMap, ({extensions = []}) => {
      return extensions.includes(extname);
    })?true:false;
  }

}

export function isMobile() {
  const userAgent = window.navigator.userAgent;
  const ipad = userAgent.match(/(iPad).*OS\s([\d_]+)/);
  const isiPhone = !ipad && userAgent.match(/(iPhone\sOS)\s([\d_]+)/);
  const isAndroid = userAgent.match(/(Android)\s+([\d.]+)/);
  return isiPhone || isAndroid;
}

export function openShare(shareId) {
  if(isMobile()){
    window.location.href=`/shares/${shareId}`
    return;
  }
  window.open(`/shares/${shareId}`);
}

export function compareVersions(version1, version2) {
  // 将版本号拆分成数字数组
  var arr1 = version1.split('.');
  var arr2 = version2.split('.');

  // 遍历数字数组进行逐段比较
  for (var i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    var num1 = parseInt(arr1[i] || 0); // 如果数组长度不够，则将缺失部分补0
    var num2 = parseInt(arr2[i] || 0);

    if (num1 < num2) {
      return -1; // 版本1小于版本2
    } else if (num1 > num2) {
      return 1; // 版本1大于版本2
    }
  }

  return 0; // 版本1等于版本2
}
export function isWeiXin(){
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin1 = ua.indexOf('micromessenger') != -1;
  return isWeixin1 && (getWxEnv()) != "miniprogram";
  // return isWeixin1 && (await getWxEnv()) === "miniprogram";
}
const getWxEnv = () => {
  if(window.__wxjs_environment){
    if(window.__wxjs_environment==='miniprogram')
        return 'miniprogram'
    else
    return 'wechat'
  }
  else
   return 'wechat'
}

export function getExtensionFromUrl(url) {
  if (!url) return null;
  const match = url.match(/\.(\w+)$/);
  return match ? match[1] : null;
}
export function replaceFileExtension(filename, newExtension) {
  return filename.replace(/\.[^/.]+$/, '.' + newExtension);
}
export function getProductImage(mime,defaultImg=''){
  if(!mime || mime.length==0){
    return defaultImg
  }
  let images = _.filter(mime, {mimePurpose: 'thumbnail'});
  if(images.length==0){
    return defaultImg;
  }
  return images?.[0]?.['mimeSource']?.[0]?.['#text']??defaultImg

}

export function getQuerysByArray(date,preArray){
  let count = preArray.length;
  let str = '';
  for(let i=0;i<count;i++){

    let item = date[i]?.toISOString();
    if(item && preArray[i].length>0){
      str = str+(str.length==0?'?':'&')+preArray[i]+'='+item
    }
  }
  return str;
}
export function getBodyByArray(date,preArray){
  let count = preArray.length;
  let dic = {};
  for(let i=0;i<count;i++){

    let item = date[i]?.toISOString();
    if(item && preArray[i].length>0){
      dic[preArray[i]] = item
      // str = str+(str.length==0?'?':'&')+preArray[i]+'='+item
    }
  }
  return dic;
}

export function MillisecondToDate(milliseconds, second, minute, hour, day) {
  var hours = Math.floor(milliseconds / 3600000);
  var minutes = Math.floor((milliseconds % 3600000) / 60000);
  var seconds = Math.floor(((milliseconds % 3600000) % 60000) / 1000);
  var times = (hours > 0 ? hours + hour : "") + (minutes >0 ? minutes+minute : '')  + (seconds >0 ?  + seconds+second : '');
  if(times.length==0)
    return '0';
  return times;
}